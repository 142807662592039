import { Button, Stack } from '@mui/material';
import React, { FC, Fragment } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ILot } from '../../../shared';
import { SelectLotArticle } from './select-lot-article.component';

interface Props {
    lot: ILot | undefined;
    auctionId: string;
}

export const SelectMultipleArticles: FC<Props> = ({ lot, auctionId }) => {
    const { t } = useTranslation();
    const form = useFormContext();
    const {
        fields: articleFields,
        append,
        remove,
    } = useFieldArray({
        control: form.control,
        name: 'articles',
    });

    return (
        <div>
            <Stack alignItems="center" spacing={2} direction="column">
                {articleFields.map((field, index) => (
                    <Fragment key={field.id}>
                        <SelectLotArticle index={index} remove={remove} lot={lot} auctionId={auctionId} />
                    </Fragment>
                ))}
            </Stack>
            <Button
                onClick={() => append({ article: null })}
                color="secondary"
                sx={{ span: { mb: 0.5, mx: 1 }, width: 200 }}
            >
                <span>+</span> {t('addArticle')}
            </Button>
        </div>
    );
};
