import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    Dialog,
    DialogContent,
    Stack,
    Typography,
} from '@mui/material';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { DialogTitleWithClose, IMissingAndUnassignedLots } from '../../../shared';
import { ExpandMore } from '@mui/icons-material';

interface Props {
    open: boolean;
    close: () => void;
    ranges?: IMissingAndUnassignedLots;
}

export const MissingAuctionLots: FC<Props> = ({ open, close, ranges }) => {
    const { t } = useTranslation();

    return (
        <Dialog
            open={open}
            onClose={close}
            fullWidth
            maxWidth="md"
            sx={{ '.MuiDialog-paper': { backgroundColor: '#FAFAFA' } }}
        >
            <DialogTitleWithClose onClose={close} />

            <DialogContent sx={{ mb: 2 }}>
                <Stack
                    spacing={2}
                    direction="row"
                    justifyContent="space-between"
                    sx={{ '.MuiBox-root': { width: '50%' } }}
                >
                    <Box>
                        <Typography variant="subtitle1" mb={2}>
                            {t('missingAuctionLots')}
                        </Typography>{' '}
                        <Stack spacing={2}>
                            {ranges?.missing.map((range) => (
                                <Accordion key={range.startOfRange}>
                                    <AccordionSummary expandIcon={<ExpandMore />}>
                                        {`${range.startOfRange}${range.startOfRange !== range.endOfRange ? ` - ${range.endOfRange}` : ''}`}
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        {range.lots
                                            ?.map((lot) => `${lot.lotNumber}${lot.lotNumberSuffix || ''}`)
                                            .join(', ')}
                                    </AccordionDetails>
                                </Accordion>
                            ))}
                        </Stack>
                    </Box>

                    <Box>
                        <Typography variant="subtitle1" mb={2}>
                            {t('unassignedAuctionLots')}
                        </Typography>
                        <Stack spacing={2}>
                            {ranges?.unassigned.map((range) => (
                                <Accordion key={range.startOfRange}>
                                    <AccordionSummary expandIcon={<ExpandMore />}>
                                        {`${range.startOfRange}${range.startOfRange !== range.endOfRange ? `- ${range.endOfRange}` : ''}`}
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        {range.lots
                                            ?.map((lot) => `${lot.lotNumber}${lot.lotNumberSuffix || ''}`)
                                            .join(', ')}
                                    </AccordionDetails>
                                </Accordion>
                            ))}
                        </Stack>
                    </Box>
                </Stack>
            </DialogContent>
        </Dialog>
    );
};
