import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Dialog, DialogActions, DialogContent } from '@mui/material';
import { AxiosError } from 'axios';
import React, { FC, useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { DialogTitleWithClose, IAuction, ILot, ILotForm, InUseWarning } from '../../../shared';
import { useSaveLot } from '../../hooks';
import { lotFromFormMapper, lotToFormMapper, setLotFormDefaults } from '../../mappers';
import { useLotSchema } from '../../validators';
import { SaveLotInputs } from '../save-lot-inputs/save-lot-inputs.component';

interface Props {
    close: () => void;
    auction: IAuction;
    showLotModal: { show: boolean; lot: ILot | undefined };
    setShowLotModal: (show: boolean, lot: ILot | undefined) => void;
}

export const SaveLotDialog: FC<Props> = ({ close, auction, showLotModal, setShowLotModal }) => {
    const { t } = useTranslation();
    const [showUniqueWarning, setShowUniqueWarning] = useState(false);
    const { show, lot } = showLotModal;
    const { mutateAsync: saveLot, isPending: isSaving } = useSaveLot();

    const form = useForm<ILotForm>({
        resolver: yupResolver(useLotSchema()),
        mode: 'all',
    });

    useEffect(() => {
        if (show) {
            lot ? form.reset(lotToFormMapper(lot)) : form.reset(setLotFormDefaults());
        }
    }, [form, show, lot]);

    const onSubmit = async (item: ILotForm, next = false) => {
        try {
            await saveLot({ id: lot?.id, item: lotFromFormMapper(item, auction.id) });
            if (next) {
                setShowLotModal(true, undefined);
                form.reset(setLotFormDefaults());
                document.querySelector<HTMLInputElement>('#lotNumber-field')?.focus();
            } else {
                close();
            }
        } catch (err: unknown) {
            if ((err as AxiosError)?.response?.status === 409) {
                form.setError('lotNumber', { type: 'value' });
                form.setError('lotNumberSuffix', { type: 'value' });
                return setShowUniqueWarning(true);
            }
            throw err;
        }
    };

    return (
        <Dialog open={show} onClose={close} fullWidth maxWidth="lg" disableRestoreFocus>
            <FormProvider {...form}>
                <DialogTitleWithClose onClose={close}>
                    {lot ? `${t('editLot')} ${lot.lotNumber}` : t('newLot')}
                </DialogTitleWithClose>

                <DialogContent>
                    <SaveLotInputs lot={lot} auction={auction} setShowUniqueWarning={setShowUniqueWarning} />
                    <InUseWarning
                        show={showUniqueWarning}
                        onClose={() => setShowUniqueWarning(false)}
                        text={t('uniqueLotNumberWarning')}
                    />
                </DialogContent>

                <DialogActions>
                    <Button
                        variant="contained"
                        color="primary"
                        disabled={isSaving}
                        onClick={form.handleSubmit((data) => onSubmit(data, true))}
                    >
                        {t('saveAndNext')}
                    </Button>
                    <Button
                        variant="outlined"
                        color="primary"
                        disabled={isSaving}
                        onClick={form.handleSubmit((data) => onSubmit(data))}
                    >
                        {t('save')}
                    </Button>
                    <Button onClick={close} color="secondary" disabled={isSaving}>
                        {t('cancel')}
                    </Button>
                </DialogActions>
            </FormProvider>
        </Dialog>
    );
};
