import { Stack } from '@mui/material';
import React, { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { ControlledInput, ControlledNumberInput, IAuction, ILot } from '../../../shared';
import { SelectMultipleArticles } from '../select-multiple-articles/select-multiple-articles.component';
import { AxiosError } from 'axios';
import { useUniqueCheckLotForAuction } from '../../hooks';
import { useFormContext } from 'react-hook-form';

interface Props {
    auction: IAuction;
    lot: ILot | undefined;
    setShowUniqueWarning: (showUniqueWarning: boolean) => void;
}

export const SaveLotInputs: FC<Props> = ({ auction, lot, setShowUniqueWarning }) => {
    const { t } = useTranslation();
    const { mutateAsync: uniqueLotCheck } = useUniqueCheckLotForAuction();

    const form = useFormContext();
    const watchLotNumber = form.watch('lotNumber');
    const watchLotNumberSuffix = form.watch('lotNumberSuffix');

    const uniqueCheck = useCallback(async () => {
        try {
            await uniqueLotCheck({
                auctionId: auction.id,
                lotNumber: Number(watchLotNumber),
                lotNumberSuffix: watchLotNumberSuffix || null,
                lotId: lot?.id,
            });
            form.clearErrors(['lotNumber']);
            return setShowUniqueWarning(false);
        } catch (err: unknown) {
            if ((err as AxiosError)?.response?.status === 409) {
                form.setError('lotNumber', { type: 'value' });
                form.setError('lotNumberSuffix', { type: 'value' });
                return setShowUniqueWarning(true);
            }
            throw err;
        }
    }, [uniqueLotCheck, auction.id, watchLotNumber, watchLotNumberSuffix, lot?.id, form, setShowUniqueWarning]);

    return (
        <Stack direction="column" spacing={2} mt={2}>
            <Stack direction="row" spacing={2} pl={2}>
                <ControlledNumberInput name="lotNumber" label={t('lotNumber')} required decimalScale={0} autoFocus />
                <ControlledInput name="lotNumberSuffix" label={t('lotNumberSuffix')} onBlur={() => uniqueCheck()} />
            </Stack>

            <SelectMultipleArticles lot={lot} auctionId={auction.id} />

            <Stack direction="row" spacing={2} pl={2}>
                <ControlledNumberInput
                    name="minEstimate"
                    label={t('minEstimate')}
                    InputProps={{ startAdornment: '€ ' }}
                    sx={{ input: { pl: 1 }, minWidth: 150 }}
                />
                <ControlledNumberInput
                    name="maxEstimate"
                    label={t('maxEstimate')}
                    InputProps={{ startAdornment: '€ ' }}
                    sx={{ input: { pl: 1 }, minWidth: 150 }}
                />
            </Stack>

            <Stack direction="row" spacing={2} pl={2}>
                <ControlledInput name="descriptionNl" label={t('descriptionNl')} required multiline rows={4} />
                <ControlledInput name="descriptionEn" label={t('descriptionEn')} required multiline rows={4} />
            </Stack>
        </Stack>
    );
};
